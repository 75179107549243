import {Injectable} from '@angular/core';
import {HttpParamsService} from '../service/http-params.service';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from "@api-module/api.endpoint.constant";
import { IResponse } from '@api-module/model/common/i-response';
import {IWatchlistModel} from "@api-module/model/watchlist/i-watchlist.model";
import {IWatchlistItem} from "@api-module/model/watchlist/i-watchlist-item.model";
import {IWatchlistItemRequestModel} from "@api-module/model/watchlist/i-watchlist-item-request.model";
import {IWatchlistColumnSettingModel} from "@api-module/model/watchlist/I-watchlist-column-setting.model";
import {IArticleBasicModel} from "@api-module/model/article/i-article-basic-model";

@Injectable({
  providedIn: 'root'
})
export class WatchlistRestService {

  readonly BASE_URL: string = ApiConstant.PROTECTED + ApiConstant.WATCHLIST

  constructor(private http: HttpClient, private paramService: HttpParamsService) {
  }

  findWatchlistByAccountId() {
    return this.http.post<IResponse<IWatchlistModel[]>>(this.BASE_URL + ApiConstant.FIND_WATCHLIST_BY_ACCOUNT_ID, {});
  }
  
  findLastViewWatchlist() {
    return this.http.post<IResponse<IWatchlistModel>>(this.BASE_URL + ApiConstant.FIND_LAST_VIEW_WATCHLIST, {});
  }

  addWatchlist(listName: string,fromGeneral? : string) {
    const params = this.paramService.buildHttpParams({listName,fromGeneral});
    return this.http.post<IResponse<IWatchlistModel>>(this.BASE_URL + ApiConstant.CREATE, {}, {params});
  }

  updateWatchlist(listId: string, listName: string) {
    const params = this.paramService.buildHttpParams({listId, listName});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE, {}, {params});
  }

  updateWatchlistLastView(listId: string) {
    const params = this.paramService.buildHttpParams({listId});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE_LAST_VIEW, {}, {params});
  }

  updateWatchlistSeq(watchlistModelList: IWatchlistModel[]) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE_WATCHLIST_SEQ, watchlistModelList);
  }

  updateWatchlistNameInList(watchlistModelList: IWatchlistModel[]) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE_WATCHLIST_NAME_LIST, watchlistModelList);
  }

  deleteWatchlist(listId: string) {
    const params = this.paramService.buildHttpParams({listId});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.DELETE, {}, {params});
  }

  findAllWatchlistItemByListId(listId: string) {
    const params = this.paramService.buildHttpParams({listId});
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_ALL_WATCHLIST_ITEM_BY_LIST_ID, {}, {params});
  }

  findAllWatchlistItemCompact(listId: string) {
    const params = this.paramService.buildHttpParams({listId});
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_ALL_WATCHLIST_ITEM_COMPACT, {}, {params});
  }

  findAllWatchlistItemCompactLastViewed(listId: string, lastViewed: string) {
    const params = this.paramService.buildHttpParams({listId,lastViewed});
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_ALL_WATCHLIST_ITEM_COMPACT, {}, {params});
  }

  addWatchlistItem(watchlistItemRequestModel: IWatchlistItemRequestModel) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.ADD_WATCHLIST_ITEM, watchlistItemRequestModel);
  }

  updateWatchlistNameItemSeq(watchlistModel: IWatchlistModel) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE_WATCHLIST_NAME_ITEM_SEQ, watchlistModel)
  }

  updateWatchlistItemSeq(watchlistItemRequestModel: IWatchlistItemRequestModel[]) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.UPDATE_WATCHLIST_ITEM_SEQ, watchlistItemRequestModel);
  }

  deleteWatchlistItem(listItemId: string) {
    const params = this.paramService.buildHttpParams({listItemId});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.DELETE_WATCHLIST_ITEM, {}, {params});
  }

  deleteWatchlistItemList(listItemIdList: string[]) {
    const params = this.paramService.buildHttpParams({listItemIdList});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.DELETE_WATCHLIST_ITEM_LIST, {}, {params});
  }

  getColumnSetting() {
    return this.http.post<IResponse<IWatchlistColumnSettingModel>>(this.BASE_URL + ApiConstant.GET_COLUMN_SETTING, {});
  }

  updateColumnSetting(watchlistColumnSettingModel: IWatchlistColumnSettingModel) {
    return this.http.post<IResponse<IWatchlistColumnSettingModel>>(this.BASE_URL + ApiConstant.UPDATE_COLUMN_SETTING, watchlistColumnSettingModel);
  }

  //FSM list
  findBondExpressList() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_BOND_EXPRESS_LIST, {});
  }

  findBondExpressCompactList() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_BOND_EXPRESS_COMPACT_LIST, {});
  }

  findAllRecommendFunds() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_ALL_RECCOMEND_FUNDS, {});
  }

  findHighestDividendYieldAllList() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_HIGHEST_DIVIDEND_YIELD_ALL_LIST, {});
  }

  findHighestDividendYieldFIList() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_HIGHEST_DIVIDENT_YIELD_FI_LIST, {});
  }

  findBestPerformFund() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_BEST_PERFORM_FUNDS, {});
  }

  findTopVolumeFundsFI() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_TOP_VOLUME_FUNDS_FIXED_INCOME, {});
  }

  findTopVolumeFundsEquityAndOthers() {
    return this.http.post<IResponse<IWatchlistItem[]>>(this.BASE_URL + ApiConstant.FIND_TOP_VOLUME_FUNDS_EQUITY_AND_OTHERS, {});
  }

  // Articles
  findRelatedArticles(productIds: string[]) {
    const params = this.paramService.buildHttpParams({productIds})
    return this.http.post<IResponse<IArticleBasicModel[]>>(this.BASE_URL + ApiConstant.FIND_RELATED_ARTICLES, {}, {params});
  }

  findSavedArticles() {
    return this.http.post<IResponse<IArticleBasicModel[]>>(this.BASE_URL + ApiConstant.FIND_SAVED_ARTICLES, {});
  }

  addSavedArticle(articleId: number) {
    const params = this.paramService.buildHttpParams({articleId});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.ADD_SAVED_ARTICLE, {}, {params});
  }

  deleteSavedArticle(articleId: number) {
    const params = this.paramService.buildHttpParams({articleId});
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.DELETE_SAVED_ARTICLE, {}, {params});
  }

  //start here
  addStockRecentSearchHistory(symbol: string) {
    const params = this.paramService.buildHttpParams({symbol});
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.ADD_STOCK_RECENT_SEARCH_HISTORY ,{},{params});
  }

  findStockRecentSearchHistory() {
    const params = this.paramService.buildHttpParams({});
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_STOCK_RECENT_SEARCH_HISTORY ,{},{});
  }

  findAllWatchlistStockByListId(listId: string) {
    const params = this.paramService.buildHttpParams({listId});
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_ALL_WATCHLIST_STOCK_BY_LIST_ID, {}, {params});
  }

  findWatchListItemByListIdProductType(listId: string, productType: string) {
    const params = this.paramService.buildHttpParams({listId, productType});
    return this.http.get<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_WATCHLIST_ITEM_BY_LIST_ID_PRODUCT_TYPE, {params});
  }

  findDefaultWatchlistStock() {
    return this.http.post<IResponse<any>>(this.BASE_URL + ApiConstant.FIND_DEFAULT_WATCHLIST_STOCK, {}, {});
  }

  addWatchlistItemInBulk(dtoList) {
    return this.http.post<IResponse<void>>(this.BASE_URL + ApiConstant.ADD_WATCHLIST_ITEM_IN_BULK, dtoList);
  }
}
