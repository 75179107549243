 <app-web-scaffold *ngIf="!showAsTable">
  <div class="px-2 pb-2">
  	<nz-row nzType="flex" nzJustify="center" *ngIf='!bondList || bondList.length<=0'>
  		<span class="text-center" translate="fsmone.no.bond.ipo.currently"></span>
    </nz-row>
    <div class="product-card-section">
    <div class="text-center" *ngIf='bondList && bondList.length>0'>
    	<table>
    	<tbody>
    	<tr class="text-left d-flex">
    	<td *ngFor="let bond of bondList; let i = index" class="m-1">
    		<b2c-product-card>
            <div nz-row class="mb-1" nzJustify="space-between">
               <nz-col nzSpan="20" class="pointer m-2">
                  <h5 class="font-weight-bold mb-0" (click)="onNavigate($event,bond)"><a [routerLink]="bondIpoPath + bond.prelaunchId"><span [innerHTML]="bond.bondName | safeHtml" ></span></a></h5>
                </nz-col>
              </div>
              <div nz-row>
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <h6 class="mb-0" [innerHTML]="bond.bondIssuer | safeHtml"></h6>
                </div>
              </div>
              <hr>
              <div nz-row class="mb-1">
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-black-6 text-sm" translate="fsmone.bond.tenor" translate-default="Tenor"></span>
                </div>
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-sm font-weight-bold">{{ bond.tenure | number: '1.0-2' }}</span>
                </div>
              </div>
              <div nz-row class="mb-1">
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-black-6 text-sm" translate="fsmone.yield.guidance" translate-default="Yield Guidance"></span>
                </div>
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-sm font-weight-bold">{{ bond.yield }}</span>
                </div>
              </div>
              <div nz-row class="mb-1">
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-black-6 text-sm" translate="fsmone.currency" translate-default="Currency"></span>
                </div>
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <span class="text-sm font-weight-bold">{{ bond.currency }}</span>
                </div>
              </div>
              <div nz-row class="text-center" *ngIf="bond.relatedArticle!=null && bond.relatedArticle!=''">
                <div nz-col nzXs="24" nzSm="24" nzXl="24">
                  <nz-divider class="my-2"></nz-divider>
                  <a class="link font-weight-bold" (click)="navigateToRelatedArticle(bond.relatedArticle)" translate="related.articles" translate-default="Related Articles" ></a>
                </div>
              </div>
            </b2c-product-card>
    	</td>
    	</tr>
    	</tbody>
    	</table>
    
<!--       <ng-container *ngFor="let bond of bondList; let i = index"> -->
        
<!--           <div class="col text-left my-2 "> -->
<!--             <b2c-product-card  -->
<!--             > -->
<!--             <div nz-row class="mb-1" nzJustify="space-between"> -->
<!--                <nz-col nzSpan="20" class="pointer"> -->
<!--                   <h5 class="font-weight-bold mb-0" (click)="onNavigate($event,bond)"><a [routerLink]="bondIpoPath + bond.prelaunchId"><span [innerHTML]="bond.bondName | safeHtml" ></span></a></h5> -->
<!--                 </nz-col> -->
<!--               </div> -->
<!--               <div nz-row> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <h6 class="mb-0" [innerHTML]="bond.bondIssuer | safeHtml"></h6> -->
<!--                 </div> -->
<!--               </div> -->
<!--               <hr> -->
<!--               <div nz-row class="mb-1"> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-black-6 text-sm" translate="fsmone.bond.tenor" translate-default="Tenor"></span> -->
<!--                 </div> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-sm font-weight-bold">{{ bond.tenure | number: '1.0-2' }}</span> -->
<!--                 </div> -->
<!--               </div> -->
<!--               <div nz-row class="mb-1"> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-black-6 text-sm" translate="fsmone.yield.guidance" translate-default="Yield Guidance"></span> -->
<!--                 </div> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-sm font-weight-bold">{{ bond.yield }}</span> -->
<!--                 </div> -->
<!--               </div> -->
<!--               <div nz-row class="mb-1"> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-black-6 text-sm" translate="fsmone.currency" translate-default="Currency"></span> -->
<!--                 </div> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <span class="text-sm font-weight-bold">{{ bond.currency }}</span> -->
<!--                 </div> -->
<!--               </div> -->
<!--               <div nz-row class="text-center" *ngIf="bond.relatedArticle!=null && bond.relatedArticle!=''"> -->
<!--                 <div nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <nz-divider class="my-2"></nz-divider> -->
<!--                   <a class="link font-weight-bold" (click)="navigateToRelatedArticle(bond.relatedArticle)" translate="related.articles" translate-default="Related Articles" ></a> -->
<!--                 </div> -->
<!--               </div> -->
<!--             </b2c-product-card> -->
<!--           </div> -->
<!--       </ng-container> -->
    </div>
    </div>
  </div>
</app-web-scaffold>

<app-mobile-scaffold *ngIf="!showAsTable">
  <div class="px-2">
  	<nz-row nzType="flex" nzJustify="center" *ngIf='!bondList || bondList.length<=0'>
  		<span class="text-center" translate="fsmone.no.bond.ipo.currently"></span>
    </nz-row>
    <div class="product-card-section">
    <div nz-row class="text-center" *ngIf='bondList && bondList.length>0'>
    <table>
    <tbody>
    <tr class="text-left d-flex">
    <td *ngFor="let bond of bondList; let i = index" class="m-1">
    	<b2c-product-card >
            <div nz-row class="mb-1" nzJustify="space-between">
               <nz-col nzSpan="20" class="pointer">
                  <h5 class="font-weight-bold" [innerHTML]="bond.bondName | safeHtml" (click)="onNavigate($event,bond)"></h5>
                </nz-col>
              </div>
              <div nz-row class="mb-1">
                <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24">
                  <h6 [innerHTML]="bond.bondIssuer | safeHtml"></h6>
                </div>
              </div>
              <hr>
              <div nz-row class="mb-1">
                  <div nz-col nzSpan="12">
                    <span class="text-black-6 text-sm" translate="fsmone.bond.tenor" translate-default="Tenor"></span>
                  </div>
                  <div nz-col nzSpan="12" class="text-right">
                    <span class="text-sm font-weight-bold">{{ bond.tenure | number: '1.0-2' }}</span>
                  </div>
                </div>
                <div nz-row class="mb-1">
                  <div nz-col nzSpan="12">
                    <span class="text-black-6 text-sm" translate="fsmone.yield.guidance" translate-default="Yield Guidance"></span>
                  </div>
                  <div nz-col nzSpan="12" class="text-right">
                    <span class="text-sm font-weight-bold">{{ bond.yield }}</span>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="12">
                    <span class="text-black-6 text-sm" translate="fsmone.currency" translate-default="Currency"></span>
                  </div>
                  <div nz-col nzSpan="12" class="text-right">
                    <span class="text-sm font-weight-bold">{{ bond.currency }}</span>
                  </div>
                </div>
                <div *ngIf="bond.relatedArticle!=null && bond.relatedArticle!=''" nz-row class="mb-1" style="text-align: center;vertical-align:bottom" class="align-bottom" (click)="navigateToRelatedArticle(bond.relatedArticle)">
                <div nz-col nzXs="24" nzSm="24" nzXl="24" class="py-3">
                  <span class="text-primary text-weight-bold" translate="related.articles" translate-default="Related Articles" ></span>
                </div>
              </div>
            </b2c-product-card>
    </td>
    </tr>
    </tbody>
    </table>
    
<!--       <ng-container *ngFor="let bond of bondList; let i = index"> -->
<!--           <div nz-col class="text-left  pb-2"> -->
<!--             <b2c-product-card > -->
<!--             <div nz-row class="mb-1" nzJustify="space-between"> -->
<!--                <nz-col nzSpan="20" class="pointer"> -->
<!--                   <h5 class="font-weight-bold" [innerHTML]="bond.bondName | safeHtml" (click)="onNavigate($event,bond)"></h5> -->
<!--                 </nz-col> -->
<!--               </div> -->
<!--               <div nz-row class="mb-1"> -->
<!--                 <div nz-col nz-col nzXs="24" nzSm="24" nzXl="24"> -->
<!--                   <h6 [innerHTML]="bond.bondIssuer | safeHtml"></h6> -->
<!--                 </div> -->
<!--               </div> -->
<!--               <hr> -->
<!--               <div nz-row class="mb-1"> -->
<!--                   <div nz-col nzSpan="12"> -->
<!--                     <span class="text-black-6 text-sm" translate="fsmone.bond.tenor" translate-default="Tenor"></span> -->
<!--                   </div> -->
<!--                   <div nz-col nzSpan="12" class="text-right"> -->
<!--                     <span class="text-sm font-weight-bold">{{ bond.tenure | number: '1.0-2' }}</span> -->
<!--                   </div> -->
<!--                 </div> -->
<!--                 <div nz-row class="mb-1"> -->
<!--                   <div nz-col nzSpan="12"> -->
<!--                     <span class="text-black-6 text-sm" translate="fsmone.yield.guidance" translate-default="Yield Guidance"></span> -->
<!--                   </div> -->
<!--                   <div nz-col nzSpan="12" class="text-right"> -->
<!--                     <span class="text-sm font-weight-bold">{{ bond.yield }}</span> -->
<!--                   </div> -->
<!--                 </div> -->
<!--                 <div nz-row> -->
<!--                   <div nz-col nzSpan="12"> -->
<!--                     <span class="text-black-6 text-sm" translate="fsmone.currency" translate-default="Currency"></span> -->
<!--                   </div> -->
<!--                   <div nz-col nzSpan="12" class="text-right"> -->
<!--                     <span class="text-sm font-weight-bold">{{ bond.currency }}</span> -->
<!--                   </div> -->
<!--                 </div> -->
<!--                 <div *ngIf="bond.relatedArticle!=null && bond.relatedArticle!=''" nz-row class="mb-1" style="text-align: center;vertical-align:bottom" class="align-bottom" (click)="navigateToRelatedArticle(bond.relatedArticle)"> -->
<!--                 <div nz-col nzXs="24" nzSm="24" nzXl="24" class="py-3"> -->
<!--                   <span class="text-primary text-weight-bold" translate="related.articles" translate-default="Related Articles" ></span> -->
<!--                 </div> -->
<!--               </div> -->
<!--             </b2c-product-card> -->
<!--           </div> -->
<!--       </ng-container> -->
    </div>
    </div>
  </div>
</app-mobile-scaffold>

<div *ngIf="showAsTable">
  <nz-table #BondIpoTable
    *ngIf="bondList && bondList.length>0"
    class="ui-table"
    [nzData]="bondList"
    [nzPageSize]="10"
    [nzTotal]="this.bondList?.length"
    [nzShowPagination]="false"
  >
    <thead class="border-bottom">
      <th nzWidth="280px">{{ 'fsmone.bond.name' | translate | titlecase }}</th>
      <th class="text-right">
        <div>{{ 'fsmone.bond.tenor' | translate }}</div>
        <div>{{ 'fsmone.yield.guidance' | translate }}</div>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let bond of BondIpoTable.data; let i = index" class="border-none">
        <td>
          <a class="font-size-bold text-black" (click)="onNavigate($event,bond)" [innerHTML]="bond.bondName | safeHtml"></a>
          <div *ngIf="bond.relatedArticle!=null && bond.relatedArticle!=''">
            <a class="link font-weight-bold" (click)="navigateToRelatedArticle(bond.relatedArticle)" translate="related.articles" translate-default="Related Articles" ></a>
          </div>
        </td>
        <td class="text-right">
          <div>{{ bond.tenure ? (bond.tenure | number: '1.0-2') : '-' }}</div>
          <div>{{ bond.yield }}</div>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-row class="my-5" nzType="flex" nzJustify="center" nzAlign="middle" *ngIf='!bondList || bondList.length<=0'>
    <b2c-ui-icon nz-col nzXs="24" nzXl="24" [name]="'emptyNoResultsIcon'" class="text-center"></b2c-ui-icon>
    <div nz-col nzXs="24" nzXl="24" class="text-center mt-3" translate="no.record.found"></div>
  </nz-row>
</div>